import React from "react";
import { Helmet } from "react-helmet";
import Container from "../../components/chat-channel/Container";
import Layout from "../../components/chat-channel/Layout";
import RegisterSection from "../../components/chat-channel/ChannelHeader";
import RightImageWithContentFeature from "../../components/chat-channel/RightImageWithContent";
import LeftImageWithContent from "../../components/chat-channel/LeftImageWithContent";
import ArrowRightICon from "../../components/common/Icons";
import FooterForm from "../../components/common/FooterForm";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import CenterContentHome from "../../components/common/CenterContentHeader";
import { RequestForm } from "../../components/form";

const cardOne = require("../../assets/images/homepage/group1.png");
const cardTwo = require("../../assets/images/homepage/group4.png");

const topImage = require("../../assets/images/10-pages/hr_upport_ind_1/hrsup_ind_1_header.png");
export const icon1 = require("../../assets/images/10-pages/hr_upport_ind_1/hrsup_ind_1_sec_1_icon.png");
export const icon2 = require("../../assets/images/10-pages/hr_upport_ind_1/hrsup_ind_1_sec_2_icon.png");
export const icon3 = require("../../assets/images/10-pages/hr_upport_ind_1/hrsup_ind_1_sec_3_icon.png");

const section_one = require("../../assets/images/10-pages/hr_upport_ind_1/hrsup_ind_1_sec_1.png");
const section_two = require("../../assets/images/10-pages/hr_upport_ind_1/hrsup_ind_1_sec_2.png");
const section_three = require("../../assets/images/10-pages/hr_upport_ind_1/hrsup_ind_1_sec_3.png");

const featureContent = [
  {
    icon: icon1,
    header: "Automate and streamline HR workflow.",
    content:
      "Our chatbot seamlessly integrates with most HRMS and central directories like Active Directory to pull data and complete process like onboarding/offboarding in a jiffy.",
    url: "/assistant/hr-automation-with-ai-chatbot",
    link: null,
    image: section_one,
  },
  {
    icon: icon2,
    header: "Don’t code; just download workflows from the marketplace ",
    content: `Workativ comes over 400+ pre-built automations on popular apps, that are ready to use from the marketplace.
      You can go live with these automations in minutes.`,
    url: "/conversational-ai-platform/marketplace",
    link: "Go to marketplace",
    image: section_two,
  },
  {
    icon: icon3,
    header: "Integrate HR apps in just a few clicks.",
    content:
      "Our chatbot comes with pre-built and secure integrations to apps, so you only need to sign-in and connect, saving a ton on time and effort. ",
    url: "/assistant/improve-hr-productivity-with-chatbot",
    link: null,
    image: section_three,
  },
];

export default function FeatureHomePage() {
  return (
    <>
      <TitleAndMetaTags
        title="Automate HR Processes and HR Inquiries with Workativ's Conversational AI Chatbot and Process Automation platform"
        description="Workativ's process automation helps automate HR tasks and employee requests at scale through workflow automation and conversational AI.  "
        keywords={[]}
        ogImage={topImage}
        ogTitle="Automate HR Processes and HR Inquiries with Workativ's Conversational AI Chatbot and Process Automation platform"
        ogDescription="Workativ's process automation helps automate HR tasks and employee requests at scale through workflow automation and conversational AI. "
      />
      <Container>
        <Layout backgroundColor={"bg_header_hr_one"} logoFor="ASSISTANT">
          <RegisterSection
            rightImage={topImage}
            backgroundColor={"bg_header_hr_one"}
          >
            <RegisterSection.FormWithContent>
              <RegisterSection.MainHeader>
                Empower your HR teams by automating processes & inquiries.
              </RegisterSection.MainHeader>
              <RegisterSection.SubHeader>
                Our chatbot helps the HR team handle employee requests at scale
                by delivering both conversations and workflow automation in a
                few clicks.
              </RegisterSection.SubHeader>
            </RegisterSection.FormWithContent>
          </RegisterSection>{" "}
          <CenterContentHome>
            <CenterContentHome.Header>
              Only Workativ offers both chatbot & process automation in a single
              solution
            </CenterContentHome.Header>
          </CenterContentHome>
          {featureContent.map((feature, index) => {
            if (index % 2 !== 0) {
              return (
                <RightImageWithContentFeature
                  image={feature.image}
                  altImage={feature.header}
                >
                  <RightImageWithContentFeature.Header>
                    <div className="icon-top-img">
                      <img src={feature.icon} alt="icon" />{" "}
                    </div>
                    <h3>{feature.header}</h3>
                  </RightImageWithContentFeature.Header>

                  <RightImageWithContentFeature.SubHeader>
                    <p>{feature.content}</p>
                  </RightImageWithContentFeature.SubHeader>
                  <RightImageWithContentFeature.Link>
                    {feature.link && (
                      <div className="card_link_landing">
                        <a href={feature.url} className="url_manipulation">
                          {feature.link}
                          <span className="arrow_svg_link">
                            <ArrowRightICon />
                          </span>
                        </a>
                      </div>
                    )}
                  </RightImageWithContentFeature.Link>
                </RightImageWithContentFeature>
              );
            }
            return (
              <LeftImageWithContent
                image={feature.image}
                altImage={feature.header}
              >
                <LeftImageWithContent.HeaderIcon>
                  <div className="icon-top-img">
                    <img src={feature.icon} alt="icon" />{" "}
                  </div>
                </LeftImageWithContent.HeaderIcon>

                <LeftImageWithContent.MainHeader>
                  {feature.header}
                </LeftImageWithContent.MainHeader>
                <LeftImageWithContent.SubHeader>
                  <p>{feature.content}</p>
                </LeftImageWithContent.SubHeader>
                <LeftImageWithContent.Link>
                  {feature.link && (
                    <div className="card_link_landing">
                      <a href={feature.link} className="url_manipulation">
                        Learn more{" "}
                        <span className="arrow_svg_link">
                          <ArrowRightICon />
                        </span>
                      </a>
                    </div>
                  )}
                </LeftImageWithContent.Link>
              </LeftImageWithContent>
            );
          })}
          <RequestForm isFooterForm={true} />
        </Layout>
      </Container>
    </>
  );
}
